import Layout from '@/layout'

export const periodicBenefitRoutes = [
  {
    path: '/periodicBenefit',
    component: Layout,
    name: 'PeriodicBenefit',
    alwaysShow: true,
    isGroup: true,
    meta: {
      title: '會員權益模組',
    },
    children: [
      {
        path: 'template/list',
        name: 'PeriodicBenefitTemplateList',
        component: () => import('@/views/PeriodicBenefitTemplateList/PeriodicBenefitTemplateList.vue'),
        meta: {
          title: '會員權益範本管理',
          action: 'admin.periodicBenefitTemplate.page',
        },
      },
      {
        path: 'template/edit/:id?',
        name: 'EditPeriodicBenefitTemplate',
        hidden: true,
        component: () => import('@/views/EditPeriodicBenefitTemplate/EditPeriodicBenefitTemplate.vue'),
        meta: {

          title: '編輯會員權益範本',
          action: 'admin.periodicBenefitTemplate.page',
        },
      },
      {
        path: 'template/view/:id?',
        name: 'PreviewPeriodicBenefitTemplate',
        hidden: true,
        component: () => import('@/views/PreviewPeriodBenefitTemplate/PreviewPeriodBenefitTemplate.vue'),
        meta: {
          title: '查看會員權益範本',
          action: 'admin.periodicBenefitTemplate.page',
        },
      },
      {
        path: 'record',
        name: 'PeriodicBenefitRecord',
        component: () => import('@/views/PeriodicBenefitRecord/PeriodicBenefitRecord.vue'),
        meta: {
          title: '會員權益紀錄',
          action: 'admin.periodicBenefitRecord.page',
        },
      },
    ],
  },
]

import Layout from '@/layout'

export const pointCardRoutes = [
  {
    path: '/point-card',
    component: Layout,
    name: 'pointCard',
    alwaysShow: true,
    isGroup: true,
    meta: {
      title: '點數卡模組',
    },
    children: [
      {
        path: 'setting',
        name: 'PointCardSetting',
        component: () => import('@/views/PointCard/PointCardSetting'),
        meta: {
          title: '點數卡設定',
          action: 'admin.pointCard.page',
        },
      },
      {
        path: '/create-point-card/:pointCardId?',
        name: 'CreatePointCard',
        hidden: true,
        component: () => import('@/views/PointCard/CreatePointCard/CreatePointCard'),
        meta: {
          title: '新增點數卡',
          action: 'admin.pointCard.page',
        },
      },
      {
        path: 'recode-list',
        name: 'PointCardRecordList',
        component: () => import('@/views/PointCard/PointCardRecord'),
        meta: {
          title: '點數卡紀錄',
          action: 'admin.pointCardRecord.page',
        },
      },
    ],
  },
]

<template>
  <div :data-testid="testid">
    <BaseElButton
      v-if="!hideCopy"
      type="text"
      class="btn edit"
      :disabled="copyDisabled"
      :loading="copyLoading"
      @click="$emit('copy')"
    >
      {{ copyBtn }}
    </BaseElButton>
    <BaseElButton
      v-if="!hideEdit"
      type="text"
      class="btn edit"
      :disabled="editDisabled"
      :loading="editLoading"
      @click="$emit('edit')"
    >
      {{ editBtn }}
    </BaseElButton>
    <BaseElButton
      v-if="!hideDelete"
      type="text"
      class="btn delete"
      :disabled="deleteDisabled"
      :loading="deleteLoading"
      @click="$emit('delete')"
    >
      {{ deleteBtn }}
    </BaseElButton>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue'
export default defineComponent({
  name: 'TableEditBtnGroup',
  props: {
    testName: {
      type: String,
      default: '',
    },
    copyBtn: {
      type: String,
      default: '複製',
    },
    editBtn: {
      type: String,
      default: '編輯',
    },
    deleteBtn: {
      type: String,
      default: '刪除',
    },
    copyDisabled: {
      type: Boolean,
      default: false,
    },
    editDisabled: {
      type: Boolean,
      default: false,
    },
    deleteDisabled: {
      type: Boolean,
      default: false,
    },
    hideCopy: {
      type: Boolean,
      default: true,
    },
    hideEdit: {
      type: Boolean,
      default: false,
    },
    hideDelete: {
      type: Boolean,
      default: false,
    },
    copyLoading: {
      type: Boolean,
      default: false,
    },
    editLoading: {
      type: Boolean,
      default: false,
    },
    deleteLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, { emit }) {
    const testidRoot = 'table-edit-btn-group'

    const testid = computed(() => {
      // return (type) => {
      //   return props.testName ? `${testidRoot}-${type}-${props.testName}` : `${testidRoot}-${type}`
      // }
      return props.testName ? `${testidRoot}-${props.testName}` : testidRoot
    })
    return { testid }
  },
})
</script>

<style lang="postcss" scoped>
.btn {
  @apply underline text-normal font-normal;
}
.edit {
  @apply text-action;
}

.delete {
  @apply text-danger;
}

::v-deep .el-button.is-disabled.el-button--text {
  @apply text-gray-40;
}
</style>

<template>
  <el-button ref="selfRef" v-bind="$attrs" :data-testid="testid" v-on="$listeners">
    <template v-for="(index, name) in $slots" v-slot:[name]>
      <slot :name="name" />
    </template>
  </el-button>
</template>

<script setup>
import { get, trim } from 'lodash'
import { computed, ref } from 'vue'

const props = defineProps({
  testName: {
    type: String,
    default: '',
  },
})

const testidRoot = 'el-button'
const selfRef = ref(null)

const testid = computed(() => {
  const content = trim(get(selfRef.value, '$el.textContent'))
  return props.testName ? `${testidRoot}-${props.testName}` : `${testidRoot}-${content}`
})
</script>

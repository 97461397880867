<template>
  <el-badge v-if="show" value="DEV" type="primary">
    <div class="dev-only-wrapper">
      <slot />
    </div>
  </el-badge>
</template>

<script setup>
import configuration from '@/configuration'
import { computed } from 'vue'

const show = computed(() => ['dev', 'uat'].includes(configuration('appRuntime')))
</script>

<style lang="postcss" scoped>
</style>

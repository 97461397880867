<template>
  <BaseDialog
    title="分頁設定"
    :btn1Loading="loading"
    @close="$emit('close')"
    @confirm="onConfirm"
  >
    <div>
      <BaseElForm ref="formRef" :model="formData" :rules="formRules">
        <BaseElFormItem label="分頁數量上限" prop="maxTabs">
          <BaseElInput v-model="formData.maxTabs" type="number" placeholder="請輸數量上限" />
        </BaseElFormItem>
      </BaseElForm>

      <div>
        <BaseElButton type="danger" @click="onReset">重置分頁</BaseElButton>
      </div>
    </div>
  </BaseDialog>
</template>

<script>
import { computed, defineComponent } from 'vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { useBaseForm } from '@/use/useForm'
import { isDigitRules, maxNumberRules, noEmptyRules } from '@/validation'
import { usePageTabs } from '@/use/usePageTabs'
import ls from 'local-storage'
import notifyMessage from '@/config/notifyMessage'

export default defineComponent({
  name: 'PageTabSettingModal',
  components: {
    BaseDialog,
  },
  setup (props, { emit }) {
    const { formRef, formData, initFormData, checkForm, loading } = useBaseForm()
    const { resetTabs } = usePageTabs()
    const pageTabsSettings = ls.get('page_tabs_settings')

    initFormData({
      maxTabs: pageTabsSettings.maxTabs || 15,
    })

    const formRules = computed(() => {
      const rules = {
        maxTabs: [noEmptyRules(), isDigitRules(), maxNumberRules(20)],
      }
      return rules
    })

    const onConfirm = async () => {
      if (loading.value) return
      loading.value = true
      const pass = await checkForm(formRef.value)
      if (!pass) {
        loading.value = false
        return
      }
      ls.set('page_tabs_settings', { ...formData })
      loading.value = false
      window.$message.success(notifyMessage.updateSuccess)
      emit('close')
    }

    const onReset = () => {
      const confirm = window.confirm('提醒：確定重置分頁？')
      if (confirm) resetTabs()
    }

    return { onConfirm, formRef, formData, formRules, loading, onReset }
  },
})
</script>

<style lang="postcss" scoped>

</style>

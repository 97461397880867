<template>
  <el-option ref="optionRef" v-bind="$attrs" :data-testid="testid" v-on="$listeners">
    <template v-for="(index, name) in $slots" v-slot:[name]>
      <slot :name="name" />
    </template>
    <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
      <slot :name="name" v-bind="data" />
    </template>
  </el-option>
</template>

<script setup>
import { get } from 'lodash'
import { computed, ref } from 'vue'

const props = defineProps({
  testName: {
    type: String,
    default: '',
  },
})

const testidRoot = 'el-select-option'
const optionRef = ref(null)

const testid = computed(() => {
  const selfLabel = get(optionRef.value, 'label')
  return props.testName ? `${testidRoot}-${props.testName}` : `${testidRoot}-${selfLabel}`
})

</script>

import Layout from '@/layout'
// import configuration from '@/configuration'

export const progressNotificationRoutes = [
  {
    path: '/progress-notification',
    component: Layout,
    name: 'ProgressNotification',
    alwaysShow: true,
    isGroup: true,
    meta: {
      title: '通知模組',
    },
    children: [
      {
        path: 'template',
        alwaysShow: true,
        component: () => import('@/components/RouterViewWrapper.vue'),
        meta: {
          title: '通知範本設定',
        },
        children: [
          // 通知對象
          {
            path: 'target/list',
            name: 'ProgressNotificationTargetTemplateList',
            component: () => import('@/views/NotificationTargetTemplateList/NotificationTargetTemplateList.vue'),
            meta: {
              title: '通知對象範本',
              action: 'admin.notifyTargetTemplate.page',
            },
          },
          {
            path: 'target/edit/:id?',
            name: 'ProgressNotificationTargetTemplateEdit',
            hidden: true,
            component: () => import('@/views/NotificationTargetTemplateEdit/NotificationTargetTemplateEdit.vue'),
            meta: {
              title: '通知對象範本設定',
              action: 'admin.notifyTargetTemplate.page',
            },
          },

          // 通知內容
          {
            path: 'content/list',
            name: 'ProgressNotificationContentTemplateList',
            component: () => import('@/views/NotificationContentTemplateList/NotificationContentTemplateList.vue'),
            meta: {
              title: '通知內容範本',
              action: 'admin.notifyContentTemplate.page',
            },
          },
          {
            path: 'content/edit/:id?',
            name: 'ProgressNotificationContentTemplateEdit',
            hidden: true,
            component: () => import('@/views/NotificationContentTemplateEdit/NotificationContentTemplateEdit.vue'),
            meta: {
              title: '通知內容範本設定',
              action: 'admin.notifyContentTemplate.page',
            },
          },
        ],
      },

      {
        path: '/settings',
        alwaysShow: true,
        component: {
          render (c) {
            return c('router-view')
          },
        },
        meta: {
          title: '通知設定',
        },
        children: [
          // 通知對象
          {
            path: 'reservation',
            name: 'ReservationProgressNotificationSetting',
            component: () => import('@/views/ReservationProgressNotification/ReservationProgressNotification.vue'),
            meta: {
              title: '預約通知',
              action: 'adminView.notifySettingTargetAppointment.page',
            },
          },
          {
            path: 'edit/:type/:id?',
            name: 'ReservationProgressNotificationSettingEdit',
            hidden: true,
            component: () => import('@/views/ProgressNotificationSettingEdit/ProgressNotificationSettingEdit.vue'),
            meta: {
              title: '編輯預約通知',
              action: 'adminView.notifySettingTargetAppointment.page',
            },
          },
        ],
      },

    ],
  },
]

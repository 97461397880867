<template>
  <div class="headbar flex-between flex-wrap" :style="{ height: suspend ? 'auto': '80px' }">
    <div class="flex items-center">
      <img
        data-cy="sidemenu-toggle-btn"
        style="cursor: pointer; margin-right: 24px"
        src="@/assets/icon/menu.svg"
        alt=""
        @click="toggleSideBar"
      >
      <img
        :src="logoConfig[appTheme].dpr1x"
        :srcset="`${logoConfig[appTheme].dpr1x} 1x, ${logoConfig[appTheme].dpr2x} 2x`"
        alt="brand logo"
        class="brand"
        @click="toggleSideBar"
      >
      <div class="version flex-align-end">
        <p data-cy="app-version">v {{ version }}</p>
      </div>
    </div>

    <div class="right-menu">
      <!-- <p class="text-gray-60">時區: Asia/Taipei</p> -->
      <div v-if="showQrcode" class="scan-qrcode" @click="qrcode.visible = true">
        <img src="@/assets/member/icon-scan-qrcode.svg" alt="Scan QRCode icon">
      </div>
      <DevOnly>
        <BaseElButton v-if="usePermissionsDebugger" type="info" @click="toggleFeatureKeyDebugger">🔰 權限控制面板</BaseElButton>
      </DevOnly>
      <el-dropdown
        class="avatar-container right-menu-item hover-effect"
        trigger="click"
        placement="bottom-start"
      >
        <div class="avatar-wrapper">
          <p class="username">
            {{ user.name || '' }}
            <i class="el-icon-arrow-down" style="margin-left: 13px;" />
          </p>
        </div>

        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item>
            <div>
              <router-link
                style="display:block;"
                :to="{ name: 'AccountConfig' }"
              >
                個人帳號設定
              </router-link>
            </div>
          </el-dropdown-item>
          <el-dropdown-item @click.native="logout">
            <div>
              <span style="display:block;">登出</span>
            </div>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <!--    <div>-->
    <!--      <el-menu mode="horizontal" menu-trigger="click">-->
    <!--        <el-submenu index="1" style="background: white;">-->
    <!--          <template slot="title">{{user.name}}</template>-->
    <!--          <el-menu-item index="1-1" align="center">-->
    <!--            <div @click="accountSetting = true">個人帳號設定</div>-->
    <!--          </el-menu-item>-->
    <!--          <el-menu-item index="1-1" align="center" @click.native="logout">-->
    <!--            <span style="display:block;">登出</span>-->
    <!--          </el-menu-item>-->
    <!--        </el-submenu>-->
    <!--      </el-menu>-->
    <!--    </div>-->

    <!-- QRCode Reader -->
    <div
      v-if="qrcode.visible"
      class="qrcode-reader__container"
    >
      <img
        class="qrcode-reader--close"
        src="@/assets/member/icon-close.svg"
        alt="Close icon"
        @click="qrcode.visible = false"
      >
      <div class="qrcode-reader__label">找到條碼來掃描</div>
      <qrcode-stream
        v-if="!reloadQrcode"
        class="qrcode-reader"
        :constraints="qrcodeConstraints"
        @init="onInit"
        @decode="onDecode"
      >
        <div v-if="qrcode.init" class="qrcode-reader__prompt">等待開啟相機...</div>
        <img class="qrcode-reader__border-tl" src="@/assets/member/icon-qrcode-border-tl.svg" alt="QRCode border">
        <img class="qrcode-reader__border-tr" src="@/assets/member/icon-qrcode-border-tr.svg" alt="QRCode border">
        <img class="qrcode-reader__border-bl" src="@/assets/member/icon-qrcode-border-bl.svg" alt="QRCode border">
        <img class="qrcode-reader__border-br" src="@/assets/member/icon-qrcode-border-br.svg" alt="QRCode border">
      </qrcode-stream>

      <div v-else v-loading="reloadQrcode" class="qrcode-reader" element-loading-background="rgba(0,0,0,0.6)">
        <div v-if="qrcode.init" class="qrcode-reader__prompt">等待開啟相機...</div>
        <img class="qrcode-reader__border-tl" src="@/assets/member/icon-qrcode-border-tl.svg" alt="QRCode border">
        <img class="qrcode-reader__border-tr" src="@/assets/member/icon-qrcode-border-tr.svg" alt="QRCode border">
        <img class="qrcode-reader__border-bl" src="@/assets/member/icon-qrcode-border-bl.svg" alt="QRCode border">
        <img class="qrcode-reader__border-br" src="@/assets/member/icon-qrcode-border-br.svg" alt="QRCode border">
      </div>

      <div class="p-[20px] ">
        <p class="text-white">本系統預設為「後鏡頭」，如與您的預設值不符，建議確認瀏覽器版本或更換裝置使用</p>
        <!-- <BaseElButton class="text-[20px]" type="primary" @click="changeQrcodeCamera">
          <div class="flex items-center" style="gap: 10px">
            <span class="material-icons" style="width: 20px;">
              flip_camera_android_outlined
            </span>
            <span>
              切換相機：{{ facingModeLabel[qrcodeConstraints.facingMode] }}
            </span>
          </div>
        </BaseElButton> -->
      </div>
    </div>
    <!-- suspend -->
    <SuspendNotice v-if="suspend" class="suspend" :title="suspendNotice" />
  </div>
</template>

<script>
import { defineComponent, reactive, ref, onMounted, computed } from 'vue'
import { mapGetters } from 'vuex'
import beautyLogo from '@/assets/header/logo-beauty.png'
import beautyLogo2x from '@/assets/header/logo-beauty-2x.png'
import ohbotLogo from '@/assets/header/logo-ohbot.png'
import ohbotLogo2x from '@/assets/header/logo-ohbot-2x.png'
import righttimeLogo from '@/assets/header/logo-righttime.png'
import righttimeLogo2x from '@/assets/header/logo-righttime-2x.png'
import wishMobileLogo from '@/assets/header/logo-wishmobile.png'
import wishMobileLogo2x from '@/assets/header/logo-wishmobile-2x.png'
import xuanienLogo from '@/assets/header/logo-xuanien.png'
import xuanienLogo2x from '@/assets/header/logo-xuanien-2x.png'
import { FindShopMember } from '@/api/auth'
import { getAppVersion } from '@/utils/app'
import { QrcodeStream } from 'vue-qrcode-reader'
import { usePermissions } from '@/use/permissions'
import { FindShutDownNotice } from '@/api/shutdown'
import SuspendNotice from '@/components/SuspendNotice.vue'
import { isEmpty, get, isNull } from 'lodash'
import store from '@/store'

export default defineComponent({
  name: 'Headbar',
  components: { QrcodeStream, SuspendNotice },
  setup (props, { emit }) {
    const { checkAction } = usePermissions()
    const usePermissionsDebugger = computed(() => get(store.getters, 'app/useSidebarSearch'))

    const reloadQrcode = ref(false)

    const qrcodeConstraints = reactive({
      facingMode: 'environment',
    })

    const facingModeLabel = {
      environment: '後',
      user: '前',
    }

    const toggleFeatureKeyDebugger = () => {
      store.commit('app/SET_TOGGLE', { key: 'featureKeyDebugger', toggle: true })
    }

    const changeQrcodeCamera = () => {
      reloadQrcode.value = true
      if (qrcodeConstraints.facingMode === 'environment') qrcodeConstraints.facingMode = 'user'
      else if (qrcodeConstraints.facingMode === 'user') qrcodeConstraints.facingMode = 'environment'
      setTimeout(() => {
        reloadQrcode.value = false
      }, 500)
    }

    const suspend = ref(false)
    const suspendNotice = ref('')
    const getShutDownNotice = async () => {
      const [res, err] = await FindShutDownNotice()
      if (err) window.$message.error(err)
      if (res && !isEmpty(res)) {
        suspend.value = true
        suspendNotice.value = get(res[0], 'title')
      }
    }
    onMounted(async () => {
      await getShutDownNotice()
    })

    return {
      qrcodeConstraints,
      changeQrcodeCamera,
      checkAction,
      reloadQrcode,
      facingModeLabel,
      suspend,
      suspendNotice,
      usePermissionsDebugger,
      toggleFeatureKeyDebugger,
    }
  },
  data: () => ({
    selectShop: '',
    beautyLogo,
    beautyLogo2x,
    ohbotLogo,
    ohbotLogo2x,
    righttimeLogo,
    righttimeLogo2x,
    wishMobileLogo,
    wishMobileLogo2x,
    xuanienLogo,
    xuanienLogo2x,

    // -- QRCode
    qrcode: {
      init: true,
      visible: false,
      decodedString: '',
    },
    logoConfig: {
      beauty: {
        dpr1x: beautyLogo,
        dpr2x: beautyLogo2x,
      },
      ohbot: {
        dpr1x: ohbotLogo,
        dpr2x: ohbotLogo2x,
      },
      righttime: {
        dpr1x: righttimeLogo,
        dpr2x: righttimeLogo2x,
      },
      wishmobile: {
        dpr1x: wishMobileLogo,
        dpr2x: wishMobileLogo2x,
      },
      salesOhbot: {
        dpr1x: wishMobileLogo,
        dpr2x: wishMobileLogo2x,
      },
      xuanien: {
        dpr1x: xuanienLogo,
        dpr2x: xuanienLogo2x,
      },
    },
  }),
  computed: {
    ...mapGetters([
      'appTheme',
      'shop',
      'userPlanFeature',
    ]),
    version () {
      return getAppVersion()
    },
    sidebar () {
      return this.$store.getters.sidebar
    },

    user () {
      return this.$store.getters.user || {}
    },
    logo () {
      let url = ''
      if (this.appTheme === 'beauty') url = beautyLogo
      if (this.appTheme === 'ohbot') url = ohbotLogo
      return url
    },

    isFeatureScanQrcodeValid () {
      return this.userPlanFeature['admin.base.scanQrcode']
    },

    showQrcode () {
      return this.checkAction('admin.base.scanQrcode') && !this.$route.meta.disabledQrcodeReader
      // return this.isFeatureScanQrcodeValid && !this.$route.meta.disabledQrcodeReader
    },
  },
  methods: {
    toggleSideBar () {
      this.$store.dispatch('app/toggleSidebar')
    },
    async logout () {
      await this.$store.dispatch('auth/logout')
      this.$router.push('/login')
    },

    goProfile (id) {
      this.$router.push({ path: `/members/member-profile/${id}` })
    },

    async onInit (promise) {
      this.qrcode.init = true
      try {
        await promise
      } catch (error) {
        console.log('qrcode error', error)
      } finally {
        this.qrcode.init = false
      }
    },

    async onDecode (decodedString) {
      this.qrcode.decodedString = decodedString.split(':')
      this.qrcode.visible = false

      if (this.qrcode.decodedString[0] === 'bob' && this.qrcode.decodedString[1] === 'member') {
        await FindShopMember({
          shopId: this.shop,
          id: this.qrcode.decodedString[2],
        })
          .then((response) => {
            const [res, err] = response

            if (res) {
              this.$message.success('此為店家會員')
              this.goProfile(this.qrcode.decodedString[2])
            }
            if (err) this.$message.error('非店家會員')
          })
      } else this.$message.error('不可用的 QRCode')
    },
  },
})
</script>

<style lang="scss" scoped>
// .el-menu {
//   border-radius: 0 !important;
// }
.headbar {
  @apply flex items-center bg-white box-border z-[1000];
  @apply py-[28px] px-[50px]  rounded-br-[50px] ;
  box-shadow: 0 4px 6px rgb(95 95 95 / 10%);
}

.brand {
  // box-sizing: border-box;
  // width: 120px;
  height: auto;
  max-width: 100%;
}

.el-menu-item {
  border-radius: 0 !important;
  background: #f6f7fe !important;
}

a {
  color: inherit;
  text-decoration: none;
}

.username {
  font-weight: 400;
  font-size: 16px;
  color: #2d271b;
  cursor: pointer;
}

.version {
  font-size: 16px;
  color: #6d7281;
  font-weight: 400;
  text-align: end;
  margin: 0 24px 0 40px;
}

.scan-qrcode {
  // margin: 0 20px 0 auto;
  cursor: pointer;
}

.qrcode-reader__container {
  z-index: 100;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .6);
}

.qrcode-reader--close {
  position: absolute;
  top: 92.5px;
  right: 92.5px;
  width: 35px;
  height: 35px;
  cursor: pointer;
}

.qrcode-reader__label {
  color: white;
  font-size: 25px;
  line-height: 28px;
}

.qrcode-reader__label {
  color: white;
  font-size: 25px;
  line-height: 28px;
}

.qrcode-reader {
  position: relative;
  margin-top: 50px;
  width: 440px !important;
  height: 440px !important;
}

.qrcode-reader__prompt {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: white;
  background-color: rgba(0, 0, 0, .6);
}

.qrcode-reader__border-tl,
.qrcode-reader__border-tr,
.qrcode-reader__border-bl,
.qrcode-reader__border-br {
  position: absolute;
}

.qrcode-reader__border-tl {
  top: -6px;
  left: -6px;
}

.qrcode-reader__border-tr {
  top: -6px;
  right: -6px;
}

.qrcode-reader__border-bl {
  bottom: -6px;
  left: -6px;
}

.qrcode-reader__border-br {
  bottom: -6px;
  right: -6px;
}
.suspend {
  flex-basis: 100%;
  margin-top: 10px;
}

.right-menu {
@apply flex items-center gap-[20px];
}
</style>

<template>
  <el-dialog
    data-testid="delete-dialog"
    :title="title"
    :visible="true"
    :width="width"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <span>{{ content }}</span>
    <span slot="footer" class="dialog-footer">
      <div class>
        <BaseElButton data-testid="delete-dialog-cancel-btn" plain @click="$emit('close')">{{ cancelBtnString }}</BaseElButton>
        <BaseElButton data-testid="delete-dialog-confirm-btn" :loading="loading" type="danger" @click="$emit('delete')">
          {{ btnString ? btnString : '刪除' }}
        </BaseElButton>
      </div>
    </span>
  </el-dialog>
</template>

<script>
// TODO 密碼輸入
export default {
  name: 'DeleteDialog',

  props: {
    title: {
      type: String,
      default: '提醒',
    },
    content: {
      type: String,
      default: '刪除後將無法復原，確定要刪除？',
    },
    show: Boolean,
    width: {
      type: String,
      default: '40%',
    },
    btnString: String,
    cancelBtnString: { type: String, default: '取消' },
    loading: Boolean,
  },
}
</script>

<style scoped lang="scss"></style>

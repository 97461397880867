<template>
  <el-form v-bind="$attrs" :data-testid="testid" v-on="$listeners" @submit.prevent.native>
    <!-- 遍历子组件非作用域插槽，并对父组件暴露 -->
    <template v-for="(index, name) in $slots" v-slot:[name]>
      <slot :name="name" />
    </template>
    <!-- 遍历子组件作用域插槽，并对父组件暴露 -->
    <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
      <slot :name="name" v-bind="data" />
    </template>
  </el-form>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  testName: {
    type: String,
    default: '',
  },
})

const testidRoot = 'el-form'

const testid = computed(() => {
  return props.testName ? `${testidRoot}-${props.testName}` : testidRoot
})
</script>

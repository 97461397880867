<template>
  <el-radio ref="selfRef" v-bind="$attrs" :data-testid="testid" v-on="$listeners">
    <template v-for="(index, name) in $slots" v-slot:[name]>
      <slot :name="name" />
    </template>
  </el-radio>
</template>

<script setup>
import { get } from 'lodash'
import { computed, ref } from 'vue'

const props = defineProps({
  testName: {
    type: String,
    default: '',
  },
})

const testidRoot = 'el-radio'
const selfRef = ref(null)
const testid = computed(() => {
  const selfLabel = get(selfRef.value, '$el.textContent')
  return props.testName ? `${testidRoot}-${props.testName}` : `${testidRoot}-${selfLabel}`
})
</script>

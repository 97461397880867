// 會員商城
export const MemberShopParameters = [
  {
    path: '/parameter/member-shop/parameter-setting',
    name: 'MemberShopParameterSetting',
    component: () => import('@/views/Parameters/MemberShop/MemberShopParameters.vue'),
    meta: {
      title: '會員商城設定',
      // action: 'admin.ecommerceConfig.page',
    },
    children: [
      {
        path: '/member-shop/payment-settings',
        redirect: '/parameter/member-shop/payment-settings',
        meta: {
          title: '商城付款設定',
          action: 'admin.memberStorePaymentConfig.page',
        },
      },
      {
        path: '/member-shop/invoice-settings',
        redirect: '/parameter/member-shop/invoice-settings',
        meta: {
          title: '商城發票設定',
          action: 'admin.memberStoreInvoiceConfig.page',
        },
      },
      {
        path: '/member-shop/promo-settings',
        redirect: '/parameter/member-shop/promo-settings',
        meta: {
          title: '商城優惠設定',
          action: 'admin.memberStoreRewardActivity.page',
        },
      },
      {
        path: '/member-shop/public-page-settings',
        redirect: '/parameter/member-shop/public-page-settings',
        meta: {
          title: '審查公開頁設定',
          action: 'admin.memberStorePublicPageConfig.page',
        },
      },
    ],
  },
  {
    path: '/parameter/member-shop/payment-settings',
    component: () => import('@/views/Parameters/MemberShop/components/MemberShopPaymentSetting.vue'),
    name: 'MemberShopPaymentSettings',
    hidden: true,
    meta: {
      title: '商城付款設定',
      action: 'admin.memberStorePaymentConfig.page',
    },
  },
  {
    path: '/parameter/member-shop/invoice-settings',
    component: () => import('@/views/Parameters/MemberShop/components/MemberShopIvoiceSetting.vue'),
    name: 'MemberShopInvoiceSettings',
    hidden: true,
    meta: {
      title: '商城發票設定',
      action: 'admin.memberStoreInvoiceConfig.page',
    },
  },
  {
    path: '/parameter/member-shop/promo-settings',
    component: () => import('@/views/Parameters/MemberShop/components/MemberShopPromoSetting.vue'),
    name: 'MemberShopPromoSettings',
    hidden: true,
    meta: {
      title: '商城優惠設定',
      action: 'admin.memberStorePromotion.page',
    },
  },
  {
    path: '/parameter/member-shop/public-page-settings',
    component: () => import('@/views/Parameters/MemberShop/components/MemberShopPublicPageSetting.vue'),
    name: 'MemberShopPublicPageSettings',
    hidden: true,
    meta: {
      title: '審查公開頁設定',
      action: 'admin.memberStorePublicPageConfig.page',
    },
  },
]

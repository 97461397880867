<template>
  <el-checkbox v-bind="$attrs" :data-testid="testid" v-on="$listeners">
    <template v-for="(index, name) in $slots" v-slot:[name]>
      <slot :name="name" />
    </template>
  </el-checkbox>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  testName: {
    type: String,
    default: '',
  },
})

const testidRoot = 'el-checkbox'

const testid = computed(() => {
  return props.testName ? `${testidRoot}-${props.testName}` : testidRoot
})
</script>

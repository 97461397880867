<template>
  <div class="feature-key-checker">
    <BaseElSelect v-model="checkType">
      <BaseElSelectOption
        v-for="option in checkOptions"
        :key="option.value"
        :label="option.label"
        :value="option.value"
      />
    </BaseElSelect>

    <el-divider />

    <div class="flex flex-col" style="gap: 20px">
      <div v-for="(feature, idx) in featureKeys" :key="`feature-${idx}`" class="flex items-center" style="gap: 20px">
        <BaseElInput v-model="featureKeys[idx].feature" clearable placeholder="請輸入 featureKey (不需打 admin.)" />
        <div class="cursor-pointer" style="height: 30px" @click="removeFeature(idx)">
          <MaterialIcon color="pink" size="30">close</MaterialIcon>
        </div>
      </div>
    </div>

    <!-- <AddButton content="新增" @click="addFeature" /> -->
    <div class="add-btn" style="gap: 10px" @click="addFeature">
      <p>ADD</p>
      <MaterialIcon class="addFeature">add_outlined</MaterialIcon>
    </div>

    <div v-show="featureList.length">
      <el-divider />
      <div class="flex items-center flex-wrap" style="gap: 5px">
        <div v-for="(feature, idx) in featureList" :key="`feature-${idx}-${feature}`">
          <Tag type="action">{{ feature }}</Tag>
        </div>
      </div>
    </div>
    <el-divider />
    <div class="flex items-center text-white" style="gap: 10px">
      Result: <Tag :type="checkResult ? 'action' : 'danger'">{{ checkResult }}</Tag>
    </div>
  </div>
</template>

<script>
import { usePermissions } from '@/use/permissions'
import { computed, defineComponent, reactive, ref } from 'vue'
import { map, values, compact } from 'lodash'
import Tag from '@/components/Tag/Tag.vue'

export default defineComponent({
  name: 'FeatureKeyChecker',
  components: { Tag },
  setup (props) {
    const { checkActionList, checkAction } = usePermissions()
    const featureKeys = ref([{ feature: null }])
    const checkType = ref('union')

    const checkOptions = [
      { label: '完全包含', value: 'union' },
      { label: '其中包含', value: 'intersection' },
    ]

    const addFeature = () => {
      featureKeys.value.push({ feature: null })
    }

    const removeFeature = (idx) => {
      featureKeys.value.splice(idx, 1)
    }

    const featureList = computed(() => {
      return compact(map(featureKeys.value, i => i.feature ? `admin.${i.feature}` : null))
    })

    const checkResult = computed(() => {
      if (!featureKeys.value.length) return false
      //   const features = compact(map(featureKeys.value, 'feature'))
      return checkActionList(featureList.value, checkType.value)
    //   return checkActionList(map(featureKeys.value, 'feature'), checkType.value)
    })
    return { checkActionList, checkResult, featureKeys, checkType, checkOptions, addFeature, removeFeature, featureList }
  },
})
</script>

<style lang="postcss" scoped>
.add-btn {
    @apply cursor-pointer flex items-center justify-center;
    @apply border border-gray-40 rounded-md;
    @apply text-gray-40 p-[10px] text-center;
    @apply mt-[15px];
}
.add-btn:hover{
    @apply bg-primary-100;
}
</style>

import Layout from '@/layout'

export const entryControlRoutes = [
  {
    path: '/entry-control',
    component: Layout,
    name: 'entryControl',
    alwaysShow: true,
    isGroup: true,
    meta: {
      title: '進場模組',
    },
    children: [
      {
        path: 'branch',
        name: 'EntryControlBranch',
        component: () => import('@/views/EntryControl/EntryControlBranch'),
        meta: {
          title: '進場紀錄',
          action: 'admin.entryControlRecord.page',
        },
      },
      {
        path: 'recode-list/:id',
        name: 'EntryControlRecordList',
        hidden: true,
        component: () => import('@/views/EntryControl/EntryRecordList'),
        meta: {
          title: '進場紀錄',
          action: 'admin.entryControlRecord.page',
        },
      },
      {
        path: '/create-entry-record/:id',
        name: 'CreateEntryRecord',
        hidden: true,
        component: () => import('@/views/EntryControl/CreateEntryRecord/CreateEntryRecord'),
        meta: {
          title: '新增進場紀錄',
          action: 'admin.entryControlRecord.page',
        },
      },
    ],
  },
]

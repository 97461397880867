import Layout from '@/layout'

export const orgOperateDashboardRoutes = [
  {
    path: '/operate-dashboard/org',
    component: Layout,
    name: 'OrgOperateDashboard',
    alwaysShow: true,
    isGroup: true,
    meta: {
      title: '總部營運報表模組',
    },
    children: [
      {
        path: 'operate-overview',
        name: 'operateOverview',
        component: () =>
          import(
            '@/views/OrgOperateDashboard/OperateOverview/OperateOverview.vue'
          ),
        meta: {
          title: '品牌會員數據總覽',
          action: 'orgAdmin.businessDataChart.memberPage',
        },
      },
      {
        path: 'member-summary',
        name: 'MemberSummary',
        component: () =>
          import('@/views/OrgOperateDashboard/MemberSummary/MemberSummary.vue'),
        meta: {
          title: '品牌會員輪廓',
          action: 'orgAdmin.businessDataChart.memberPage',
        },
      },
      {
        path: 'member-contribution',
        component: {
          render (c) {
            return c('router-view')
          },
        },
        meta: {
          title: '品牌會員貢獻',
        },
        children: [
          {
            path: 'member-contribution-overview',
            name: 'memberContributionOverview',
            component: () =>
              import(
                '@/views/OrgOperateDashboard/MemberContributionOverview/MemberContributionOverview.vue'
              ),
            meta: {
              title: '會員貢獻總覽',
              action: 'orgAdmin.businessDataChart.memberPage',
            },
          },
          {
            path: 'first-purchase-overview',
            name: 'FirstPurchaseOverview',
            component: () =>
              import(
                '@/views/OrgOperateDashboard/FirstPurchaseOverview/FirstPurchaseOverview.vue'
              ),
            meta: {
              title: '首購回購客',
              action: 'orgAdmin.businessDataChart.memberPage',
            },
          },
          {
            path: 'repurchase-overview',
            name: 'RepurchaseOverview',
            hidden: true,
            component: () =>
              import(
                '@/views/OrgOperateDashboard/RepurchaseOverview/RepurchaseOverview.vue'
              ),
            meta: {
              title: '首購回購客',
              action: 'orgAdmin.businessDataChart.memberPage',
            },
          },
          {
            path: 'member-age-overview',
            name: 'MemberAgeOverview',
            component: () =>
              import(
                '@/views/OrgOperateDashboard/MemberAgeOverview/MemberAgeOverview.vue'
              ),
            meta: {
              title: '年齡',
              action: 'orgAdmin.businessDataChart.memberPage',
            },
          },
          {
            path: 'member-gander-overview',
            name: 'MemberGanderOverview',
            component: () =>
              import(
                '@/views/OrgOperateDashboard/MemberGanderOverview/MemberGanderOverview.vue'
              ),
            meta: {
              title: '性別',
              action: 'orgAdmin.businessDataChart.memberPage',
            },
          },
        ],
      },
    ],
  },
]

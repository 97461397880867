<template>
  <el-table v-bind="$attrs" :data-testId="testid" empty-text="暫無數據" v-on="$listeners">
    <EmptyBlock slot="empty" />
    <template v-for="(index, name) in $slots" v-slot:[name]>
      <slot :name="name" />
    </template>
    <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
      <slot :name="name" v-bind="data" />
    </template>
  </el-table>
</template>

<script setup>
import EmptyBlock from '@/components/EmptyBlock.vue'
import { computed } from 'vue'

const props = defineProps({
  testName: {
    type: String,
    default: '',
  },
})

const testidRoot = 'el-table'

const testid = computed(() => {
  return props.testName ? `${testidRoot}-${props.testName}` : testidRoot
})
</script>

<style scoped lang="postcss">

</style>

import { admin2 } from '@/api/instance'

// => 取得 FBAuth 設定
export const GetFBAuthConfig = async ({ orgId }) => {
  const res = await admin2({
    method: 'get',
    url: `/${orgId}/authServiceFbOauthConfig`,
  })
  return res
}

// => 取得 LineAuth 設定
export const GetLineAuthConfig = async ({ orgId }) => {
  const res = await admin2({
    method: 'get',
    url: `/${orgId}/authServiceLineOauthConfig`,
  })
  return res
}
// => 取得 Org 設定
export const GetOrgAuthConfig = async ({ orgId }) => {
  const res = await admin2({
    method: 'get',
    url: `/${orgId}/authServiceConfig`,
  })
  return res
}

// => 更新 Org 設定
export const UpdateOrgAuthConfig = async ({ orgId, orgName, logo, easyRegisterMode }) => {
  const res = await admin2({
    method: 'put',
    url: `/${orgId}/authServiceConfig`,
    data: {
      orgName,
      logo,
      easyRegisterMode,
    },
  })
  return res
}

// => 更新 FBAuth 設定
export const UpdateFBAuthConfig = async ({
  orgId,
  enable,
  appID,
  appSecret,
}) => {
  const res = await admin2({
    method: 'put',
    url: `/${orgId}/authServiceFbOauthConfig`,
    data: {
      enable,
      appID,
      appSecret,
    },
  })
  return res
}

// => 更新 LineAuth 設定
export const UpdateLineAuthConfig = async ({
  orgId,
  enable,
  channelID,
  channelSecret,
  directLineLogin,
  useShopLineLogin,
}) => {
  const res = await admin2({
    method: 'put',
    url: `/${orgId}/authServiceLineOauthConfig`,
    data: {
      enable,
      channelID,
      channelSecret,
      directLineLogin,
      useShopLineLogin,
    },
  })
  return res
}

// => 取得 LineAuth RedirectUrl
export const GetLineAuthRedirectUrl = async ({ orgId }) => {
  const res = await admin2({
    method: 'get',
    url: `/${orgId}/authServiceLineOauthConfig/redirectUrl`,
  })
  return res
}
// => 取得 FBAuth  RedirectUrl
export const GetFBAuthRedirectUrl = async ({ orgId }) => {
  const res = await admin2({
    method: 'get',
    url: `/${orgId}/authServiceFbOauthConfig/redirectUrl`,
  })
  return res
}

// => 取得 UserInfoSetting 設定
export const GetUserInfoSetting = async ({ orgId }) => {
  const res = await admin2({
    method: 'get',
    url: `/${orgId}/userInfoSetting`,
  })
  return res
}

// => 更新 UserInfoSetting 設定
export const UpdateUserInfoSetting = async ({ orgId, fields }) => {
  const res = await admin2({
    method: 'put',
    url: `/${orgId}/userInfoSetting`,
    data: {
      fields,
    },
  })
  return res
}

// => 取得 Line Oauth 設定
export const FindLineLoginConfigCallbackUrl = async ({ orgId }) => {
  const res = await admin2({
    method: 'get',
    url: `/${orgId}/authServiceLineOauthConfig/lineLoginConfigCallbackUrls`,
  })
  return res
}

// => 取得 Line Oauth 設定 redirectUrl
export const RecheckLineLoginConfigCallbackUrl = async ({ orgId }) => {
  const res = await admin2({
    method: 'post',
    url: `/${orgId}/authServiceLineOauthConfig/recheckLineLoginConfigCallbackUrl`,
  })
  return res
}

<template>
  <BaseElButton v-bind="$attrs" class="table-setting-icon-btutton" type="text" @click="$emit('click')">
    <span class="material-icons">
      settings
    </span>
  </BaseElButton>
</template>

<script>
export default {
  name: 'TableSettingIconButton',
}
</script>

<style lang="postcss" scoped>

.table-setting-icon-btutton {
  color: var(--gray-100) !important;
}

</style>

import Layout from '@/layout'

export const orgAiAnalysisRoutes = [
  {
    path: '/ai-analysis/org',
    component: Layout,
    name: 'OrgAIAnalysis',
    alwaysShow: true,
    isGroup: true,
    meta: {
      title: '總部 AI 智能分析',
    },
    children: [
      {
        path: 'appointment-unit-analysis',
        name: 'OrgBeautyAIAppointmentUnitAnalysis',
        component: () => import('@/views/OrgBeautyAIAppointmentUnitAnalysis/OrgBeautyAIAppointmentUnitAnalysis.vue'),
        meta: {
          title: 'AI 服務人員績效',
          action: 'orgAdmin.beautyAIAptUnitAnalysis.page',
        },
      },
      {
        path: 'appointment-unit-analysis/detail/:id',
        name: 'OrgBeautyAIAppointmentUnitAnalysisDetail',
        hidden: true,
        component: () => import('@/views/OrgBeautyAIAppointmentUnitAnalysisDetail/OrgBeautyAIAppointmentUnitAnalysisDetail.vue'),
        meta: {
          title: 'AI 服務人員績效詳情',
          action: 'orgAdmin.beautyAIAptUnitAnalysis.page',
        },
      },

      // AI 銷售統計圖表 - SalesProduct
      {
        path: 'sales-product',
        name: 'OrgBeautyAISalesProductAnalysis',
        component: () => import('@/views/OrgBeautyAISalesProductAnalysis/OrgBeautyAISalesProductAnalysis.vue'),
        meta: {
          title: 'AI 銷售統計圖表',
          action: 'orgAdmin.beautyAISalesProductAnalysis.page',
        },
      },
    ],
  },
]

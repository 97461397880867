const formUtils = {
  checkForm: (form) => {
    return new Promise((resolve) => {
      if (form.$children) {
        form.$children[0].validate((valid) => {
          if (valid) resolve(true)
          if (!valid) resolve(false)
        })
      } else {
        form.validate((valid) => {
          if (valid) resolve(true)
          if (!valid) resolve(false)
        })
      }
    })
  },

  resetForm: (form) => {
    if (form.$children) {
      // 因為 BaseElForm 會多一層，所以要往下找
      form.$children[0].resetFields()
    } else {
      form.resetFields()
    }
  },

  clearValidate: (form, fields) => {
    let formRef = form
    if (form.$children) {
      formRef = form.$children[0]
    }

    if (fields && fields.length) formRef.clearValidate(fields)
    else formRef.clearValidate()
  },
}

export default formUtils
